<template>
    <div class="profileLayout">
        <!-- <profile-side-bar /> -->

        <profile-area-wrapper
            class="mb-5 px-4 px-xl-5"
            :header="$t('profile.changePassword.header')"
            :subHeader="$t('profile.changePassword.subHeader')"
        >
            <div class="edit-password-form p-3 p-sm-5">
                <div class="mb-3">
                    <label for="Current Password" class="form-label">
                        Current Password
                    </label>
                    <input
                        v-model="old_password"
                        type="password"
                        class="form-control border-0"
                        id="currentPassword"
                    />
                    <div
                        class="error invalid-feedback d-block"
                        v-if="
                            submitStatus == 'ERROR' && !$v.old_password.required
                        "
                    >
                        Current Password is required.
                    </div>
                </div>
                <div class="mb-3">
                    <label for="New Password" class="form-label">
                        New Password
                    </label>
                    <input
                        v-model="password"
                        type="password"
                        class="form-control border-0"
                        id="newPassword"
                    />
                    <div
                        class="error invalid-feedback d-block"
                        v-if="submitStatus == 'ERROR' && !$v.password.required"
                    >
                        New Password is required.
                    </div>
                </div>
                <div class="mb-3">
                    <label for="Confirm Password" class="form-label">
                        Confirm Password
                    </label>
                    <input
                        v-model="password_confirmation"
                        type="password"
                        class="form-control border-0"
                        id="confirmPassword"
                    />
                    <div
                        class="error invalid-feedback d-block"
                        v-if="
                            submitStatus == 'ERROR' &&
                                !$v.password_confirmation.required
                        "
                    >
                        Confirm Password is required.
                    </div>
                    <div
                        class="error invalid-feedback d-block"
                        v-if="
                            submitStatus == 'ERROR' &&
                                !$v.password_confirmation.sameAsPassword
                        "
                    >
                        Confirm Password must be same as new password.
                    </div>
                </div>

                <div class="mb-3 edit-btn">
                    <button class="border-0" @click="handleClick">
                        {{ $t('profile.changePassword.button') }}
                    </button>
                </div>
            </div>

            <transition name="fade">
                <div
                    v-if="showAlert"
                    :class="[error ? 'alert-danger' : 'alert-success']"
                    class="alert alert-success alert-dismissible fade show"
                    role="alert"
                >
                    {{ error ? error : 'Password changed successfully!' }}
                    <a
                        href="javascript:void(0)"
                        class="btn-close"
                        @click="showAlert = false"
                    ></a>
                </div>
            </transition>
        </profile-area-wrapper>
    </div>
</template>

<script>
// import ProfileSideBar from '../reusable/ProfileSideBar';
import ProfileAreaWrapper from '../reusable/ProfileAreaWrapper';
import ChangePassword from '@/graphql/mutations/auth/ChangePassword.gql';

// vuelidate
import { validationMixin } from 'vuelidate';
import { required, sameAs } from 'vuelidate/lib/validators';

export default {
    mixins: [validationMixin],

    components: {
        // 'profile-side-bar': ProfileSideBar,
        'profile-area-wrapper': ProfileAreaWrapper,
    },

    data() {
        return {
            submitStatus: null,
            showAlert: false,
            error: '',
            password: '',
            old_password: '',
            password_confirmation: '',
        };
    },

    validations: {
        password: {
            required,
        },
        old_password: {
            required,
        },
        password_confirmation: {
            required,
            sameAsPassword: sameAs('password'),
        },
    },

    methods: {
        handleClick() {
            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR';
            } else {
                this.$apollo
                    .mutate({
                        mutation: ChangePassword,

                        variables: {
                            password: this.password,
                            old_password: this.old_password,
                            password_confirmation: this.password_confirmation,
                        },
                    })
                    .then(response => {
                        console.log(response.data);
                        this.submitStatus = 'OK';
                        this.error = '';
                        this.showAlert = true;
                    })
                    .catch(error => {
                        console.log(error.graphQLErrors);
                        this.submitStatus = 'ERROR';
                        this.error =
                            error.graphQLErrors[0].extensions.errors.password;
                        this.showAlert = true;
                    });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
// @import '@/sass/components/profileLayout.scss';
@import '@/sass/components/profilePassword.scss';
</style>
