<template>
    <change-password />
</template>

<script>
import ChangePassword from '../../components/profile/ChangePassword.vue';

export default {
    components: {
        'change-password': ChangePassword,
    },
};
</script>
