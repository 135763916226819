var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"profileLayout"},[_c('profile-area-wrapper',{staticClass:"mb-5 px-4 px-xl-5",attrs:{"header":_vm.$t('profile.changePassword.header'),"subHeader":_vm.$t('profile.changePassword.subHeader')}},[_c('div',{staticClass:"edit-password-form p-3 p-sm-5"},[_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"Current Password"}},[_vm._v(" Current Password ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.old_password),expression:"old_password"}],staticClass:"form-control border-0",attrs:{"type":"password","id":"currentPassword"},domProps:{"value":(_vm.old_password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.old_password=$event.target.value}}}),(
                        _vm.submitStatus == 'ERROR' && !_vm.$v.old_password.required
                    )?_c('div',{staticClass:"error invalid-feedback d-block"},[_vm._v(" Current Password is required. ")]):_vm._e()]),_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"New Password"}},[_vm._v(" New Password ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control border-0",attrs:{"type":"password","id":"newPassword"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),(_vm.submitStatus == 'ERROR' && !_vm.$v.password.required)?_c('div',{staticClass:"error invalid-feedback d-block"},[_vm._v(" New Password is required. ")]):_vm._e()]),_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"Confirm Password"}},[_vm._v(" Confirm Password ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password_confirmation),expression:"password_confirmation"}],staticClass:"form-control border-0",attrs:{"type":"password","id":"confirmPassword"},domProps:{"value":(_vm.password_confirmation)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password_confirmation=$event.target.value}}}),(
                        _vm.submitStatus == 'ERROR' &&
                            !_vm.$v.password_confirmation.required
                    )?_c('div',{staticClass:"error invalid-feedback d-block"},[_vm._v(" Confirm Password is required. ")]):_vm._e(),(
                        _vm.submitStatus == 'ERROR' &&
                            !_vm.$v.password_confirmation.sameAsPassword
                    )?_c('div',{staticClass:"error invalid-feedback d-block"},[_vm._v(" Confirm Password must be same as new password. ")]):_vm._e()]),_c('div',{staticClass:"mb-3 edit-btn"},[_c('button',{staticClass:"border-0",on:{"click":_vm.handleClick}},[_vm._v(" "+_vm._s(_vm.$t('profile.changePassword.button'))+" ")])])]),_c('transition',{attrs:{"name":"fade"}},[(_vm.showAlert)?_c('div',{staticClass:"alert alert-success alert-dismissible fade show",class:[_vm.error ? 'alert-danger' : 'alert-success'],attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.error ? _vm.error : 'Password changed successfully!')+" "),_c('a',{staticClass:"btn-close",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.showAlert = false}}})]):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }